import React, { useEffect, useState, ReactNode } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import ResetPasswordPage from './ResetPasswordPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import { getToken, verifyToken } from './TokenUtils';
import Dashboard from './Dashboard';
import HOAPage from './HOAPage';
import HOADetailPage from './HOADetailPage';
import HomeDetailPage from './HomeDetailPage';
import ViolationPage from './ViolationPage';
import ViolationList from './ViolationList';
import ViolationDetailPage from './ViolationDetailPage';
import LoadingSpinner from './LoadingSpinner';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = getToken();
      const auth = await verifyToken(token);
      setIsAuthenticated(auth);
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

const App: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/home" element={<Dashboard currentPage="home" />} />
        <Route path="/hoas" element={<HOAPage currentPage="hoas" />} />
        <Route
          path="/violations"
          element={<ViolationPage currentPage="violations" />}
        />
        <Route path="/hoa/:hoaId/violations" element={<ViolationList />} />
        <Route
          path="/hoa/:hoaId/home/:homeId/violations"
          element={<ViolationList />}
        />
        <Route
          path="/hoa/:hoaId/violation/:violationId"
          element={<ViolationDetailPage />}
        />
        <Route path="/hoa/:id" element={<HOADetailPage />} />
        <Route path="/hoa/:hoaId/home/:homeId" element={<HomeDetailPage />} />
        {/* More routes as needed */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </div>
  );
};

export default App;
