export const stripPhoneFormatting = (phone: string): string => {
  return phone.replace(/\D/g, '');
};

export const formatPhoneNumber = (phone: string): string => {
  const cleaned = stripPhoneFormatting(phone);
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }
  if (cleaned.length === 11) {
    return `${cleaned[0]} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }
  return phone; // Return original if not 10 or 11 digits
};
