import React, { useState, useEffect } from 'react';
import { Heading } from './Components/Catalyst/heading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './Components/Catalyst/table';
import { Input, InputGroup } from './Components/Catalyst/input';
import {
  ChevronDownIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  PhotoIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ViolationCreate, ViolationRead } from './vio-api-client';
import { violationService } from './ApiClients';
import { Button } from './Components/Catalyst/button';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from './Components/Catalyst/dialog';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from './Components/Catalyst/fieldset';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './Components/Catalyst/dropdown';
import { Divider } from './Components/Catalyst/divider';
import { Textarea } from './Components/Catalyst/textarea';
import ShowAlert from './ShowAlert';
import LoadingSpinner from './LoadingSpinner';

interface FormErrors {
  [key: string]: string | undefined;
}

interface TouchedFields {
  [key: string]: boolean;
}

interface ViolationListProps {
  hoaId?: number | null;
  homeId?: number | null;
  title?: string | null;
}

const initialViolationState: ViolationCreate = {
  home_id: 0,
  violation_type: 'Select Type',
  severity: 'Select Severity',
  description: '',
  message: '',
  date_of_violation: new Date().toISOString().split('T')[0],
  status: 'Pending',
  due_date: null,
  document_url: null,
  image_url: null,
  fine_amount: null,
};

const ViolationList: React.FC<ViolationListProps> = ({
  hoaId,
  homeId,
  title,
}) => {
  const navigate = useNavigate();
  const [violations, setViolations] = useState<ViolationRead[]>([]);
  const [filteredViolations, setFilteredViolations] = useState<ViolationRead[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [showCreateViolationError, setShowCreateViolationError] =
    useState(false);
  const [showLoadingError, setShowLoadingError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorTitle, setErrorTitle] = useState<string | null>(null);
  const [fetchErrorTitle, setFetchErrorTitle] = useState<string>('');
  const [fetchError, setFetchError] = useState<string>('');
  const [showFetchError, setShowFetchError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [newViolation, setNewViolation] = useState<ViolationCreate>(
    initialViolationState
  );

  const severityOptions = ['Minor', 'Moderate', 'Major', 'Critical'];
  const typeOptions = [
    'Maintenance',
    'Noise',
    'Architectural',
    'Parking',
    'Landscaping',
    'Pets',
    'Trash',
    'Other',
  ];

  useEffect(() => {
    const fetchViolations = async () => {
      try {
        setLoading(true);
        let response;

        if (homeId && hoaId) {
          // Fetch violations for specific home
          response = await violationService.getViolationsByHome({
            hoaId,
            homeId,
          });
          setViolations(response.data);
          setFilteredViolations(response.data);
        } else if (hoaId) {
          // Fetch all violations for HOA
          response = await violationService.getViolationsByHoa({
            hoaId,
          });
          setViolations(response.data.violations);
          setFilteredViolations(response.data.violations);
        } else {
          response = await violationService.getAllViolations();
          setViolations(response.data.violations);
          setFilteredViolations(response.data.violations);
        }
        setError(null);
      } catch (err) {
        console.error('Error fetching violations:', error);

        let errorMessage =
          'An unexpected error occurred while fetching violations.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setFetchErrorTitle('Failed to Load Violations');
        setFetchError(errorMessage);
        setShowFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchViolations();
  }, [hoaId, homeId]);

  useEffect(() => {
    const filtered = violations.filter(
      (violation) =>
        violation.description
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        violation.violation_type
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredViolations(filtered);
  }, [searchTerm, violations]);

  const handleCreateViolation = () => {
    if (hoaId && homeId) {
      setNewViolation({ ...initialViolationState, home_id: homeId });
      setIsDialogOpen(true);
    }
  };

  const resetForm = () => {
    setNewViolation({ ...initialViolationState, home_id: homeId || 0 });
    setFormErrors({});
    setTouchedFields({});
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    resetForm();
  };

  const isValidUrl = (string: string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const validateField = (
    name: keyof ViolationCreate,
    value: any
  ): string | undefined => {
    switch (name) {
      case 'violation_type':
        return !typeOptions.includes(value)
          ? 'Please select a valid violation type'
          : '';
      case 'severity':
        return !severityOptions.includes(value)
          ? 'Please select a valid severity level'
          : '';
      case 'description':
        return !value
          ? 'Description is required'
          : value.length > 500
            ? 'Description must be less than 500 characters'
            : '';
      case 'message':
        return !value ? 'Message is required' : '';
      case 'fine_amount':
        if (!value) return '';
        if (isNaN(value) || Number(value) < 0)
          return 'Fine amount must be a positive number';
        if (value > 10000) return 'Fine amount cannot exceed $10,000.00';
        return '';
      case 'date_of_violation':
        return !value
          ? 'Date of violation is required'
          : !isValidDate(value)
            ? 'Please enter a valid date'
            : '';
      case 'due_date':
        return !isValidDate(value) ? 'Please enter a valid date' : '';
      case 'image_url':
      case 'document_url':
        if (!value) return '';
        return !isValidUrl(value)
          ? 'Please enter a valid URL (e.g., https://example.com)'
          : '';
      default:
        return '';
    }
  };

  const handleBlur = (
    e: React.FocusEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof ViolationCreate, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      | { target: { name: string; value: string } }
  ) => {
    const { name, value } = e.target;

    if (name === 'fine_amount') {
      // Remove any non-digit characters except decimal point
      const numericValue = value.replace(/[^\d.]/g, '');
      // Ensure only valid decimal numbers
      if (numericValue === '' || /^\d*\.?\d{0,2}$/.test(numericValue)) {
        setNewViolation((prev) => ({
          ...prev,
          [name]: numericValue === '' ? null : parseFloat(numericValue),
        }));
      }
    } else {
      setNewViolation((prev) => ({ ...prev, [name]: value }));
    }

    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof ViolationCreate, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name] = error;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    (Object.keys(newViolation) as Array<keyof ViolationCreate>).forEach(
      (key) => {
        const error = validateField(key, newViolation[key]);
        if (error) {
          errors[key] = error;
        }
      }
    );
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSaveViolation = async () => {
    if (validateForm() && hoaId) {
      try {
        const response = await violationService.createViolation({
          hoaId,
          violationCreate: newViolation,
        });
        setViolations((prevViolations) => [...prevViolations, response.data]);
        handleDialogClose();
      } catch (err) {
        console.error('Error creating violation:', err);

        let errorMessage = 'An unexpected error occurred. Please try again.';

        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof error === 'string') {
          errorMessage = error;
        }

        setErrorTitle('Failed to create violation. Please review your data.');
        setError(errorMessage);
        setShowCreateViolationError(true);
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (violation: ViolationRead) => {
    navigate(`/hoa/${violation.hoa_id}/violation/${violation.id}`);
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    // Check required fields
    const requiredFields = [
      'violation_type',
      'severity',
      'description',
      'message',
      'date_of_violation',
    ];

    return requiredFields.every((field) => {
      const value = newViolation[field as keyof ViolationCreate];
      // Check if the field has a value and it's not the default placeholder
      if (!value) return false;
      if (field === 'violation_type' && value === 'Select Type') return false;
      if (field === 'severity' && value === 'Select Severity') return false;
      if (field === 'date_of_violation') {
        return typeof value === 'string' && isValidDate(value);
      }
      return true;
    });
  };

  const handleFileUpload = (type: 'image' | 'document') => {
    // For now, just set default URLs
    const newValue =
      type === 'image' ? 'https://imageurl.com' : 'https://documenturl.com';
    handleInputChange({
      target: {
        name: type === 'image' ? 'image_url' : 'document_url',
        value: newValue,
      },
    } as any);
  };

  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString();
  };

  const isValidDate = (dateString: string | null | undefined): boolean => {
    if (!dateString) return true; // null/undefined is OK for optional dates
    // Check if it's a complete date string (YYYY-MM-DD)
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateString)) return false;

    // Check if it's a valid date (not like 2024-13-45)
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const getSeverityColor = (severity: string) => {
    switch (severity.toLowerCase()) {
      case 'high':
        return 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100';
      case 'low':
        return 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100';
      default:
        return 'bg-zinc-100 text-zinc-800 dark:bg-zinc-800 dark:text-zinc-100';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'open':
        return 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100';
      case 'resolved':
        return 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100';
      case 'in progress':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100';
      default:
        return 'bg-zinc-100 text-zinc-800 dark:bg-zinc-800 dark:text-zinc-100';
    }
  };

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <Heading level={4}>
          {title || (homeId ? 'Home Violations' : 'Violations List')}
        </Heading>
        <div className="flex items-center space-x-4">
          {hoaId && homeId && (
            <Button onClick={handleCreateViolation}>
              <PlusIcon className="h-5 w-5 mr-2" />
              New Violation
            </Button>
          )}
          <div className="w-64">
            <InputGroup>
              <MagnifyingGlassIcon data-slot="icon" />
              <Input
                type="search"
                placeholder="Search violations..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <ShowAlert
        alertType="error"
        alertTitle={fetchErrorTitle}
        alertMessage={fetchError}
        isVisible={showFetchError}
        onClose={() => setShowFetchError(false)}
        timeout={5000}
        isInline={true}
      />
      <div className="bg-white dark:bg-zinc-800 rounded-lg shadow transition-colors duration-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <div className="max-h-[500px] overflow-y-auto">
                  <Table>
                    <TableHead className="sticky top-0 z-10 bg-white dark:bg-zinc-800">
                      <TableRow>
                        <TableHeader>Type</TableHeader>
                        <TableHeader>Description</TableHeader>
                        <TableHeader>Severity</TableHeader>
                        <TableHeader>Date</TableHeader>
                        <TableHeader>Due Date</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Fine Amount</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredViolations.map((violation) => (
                        <TableRow
                          key={violation.id}
                          onClick={() => handleRowClick(violation)}
                          className="cursor-pointer hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-150"
                        >
                          <TableCell>{violation.violation_type}</TableCell>
                          <TableCell>{violation.description}</TableCell>
                          <TableCell>
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getSeverityColor(
                                violation.severity
                              )}`}
                            >
                              {violation.severity}
                            </span>
                          </TableCell>
                          <TableCell>
                            {formatDate(violation.date_of_violation)}
                          </TableCell>
                          <TableCell>
                            {formatDate(violation.due_date)}
                          </TableCell>
                          <TableCell>
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                                violation.status
                              )}`}
                            >
                              {violation.status}
                            </span>
                          </TableCell>
                          <TableCell>
                            {violation.fine_amount
                              ? formatter.format(violation.fine_amount)
                              : '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={isDialogOpen} onClose={handleDialogClose} size="2xl">
        <div className="relative">
          <DialogTitle className="text-5xl">New Violation</DialogTitle>
          <button
            onClick={handleDialogClose}
            className="absolute right-0 top-0 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
            aria-label="Close dialog"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
          </button>
        </div>
        <Divider className="mt-4 mb-6" />
        <div className="mb-4">
          <ShowAlert
            alertType="error"
            alertTitle={errorTitle ?? 'Something went wrong'}
            alertMessage={error ?? 'Please try again'}
            isVisible={showCreateViolationError}
            onClose={() => setShowCreateViolationError(false)}
            timeout={5000}
            isInline={true}
          />
        </div>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <div className="grid grid-cols-2 gap-6">
                <Field>
                  <Label htmlFor="violation_type" className="mr-4">
                    Violation Type*
                  </Label>
                  <Description></Description>
                  <Dropdown>
                    <DropdownButton outline>
                      {newViolation?.violation_type || 'Select Type'}
                      <ChevronDownIcon className="h-5 w-5 ml-2" />
                    </DropdownButton>
                    <DropdownMenu>
                      {typeOptions.map((type) => (
                        <DropdownItem
                          key={type}
                          onClick={() =>
                            handleInputChange({
                              target: {
                                name: 'violation_type',
                                value: type,
                              },
                            } as any)
                          }
                        >
                          {type}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {touchedFields.violation_type &&
                    formErrors.violation_type && (
                      <ErrorMessage>{formErrors.violation_type}</ErrorMessage>
                    )}
                </Field>

                <Field>
                  <Label htmlFor="severity" className="mr-4">
                    Severity*
                  </Label>
                  <Description></Description>
                  <Dropdown>
                    <DropdownButton outline>
                      {newViolation?.severity || 'Select Severity'}
                      <ChevronDownIcon className="h-5 w-5 ml-2" />
                    </DropdownButton>
                    <DropdownMenu>
                      {severityOptions.map((severity) => (
                        <DropdownItem
                          key={severity}
                          onClick={() =>
                            handleInputChange({
                              target: {
                                name: 'severity',
                                value: severity,
                              },
                            } as any)
                          }
                        >
                          {severity}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {touchedFields.severity && formErrors.severity && (
                    <ErrorMessage>{formErrors.severity}</ErrorMessage>
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Field>
                  <Label htmlFor="fine_amount">Fine Amount</Label>
                  <Input
                    id="fine_amount"
                    name="fine_amount"
                    type="number"
                    min="0"
                    step="0.01"
                    value={newViolation.fine_amount || ''}
                    onChange={handleInputChange}
                    placeholder="Enter fine amount (optional)"
                  />
                  {touchedFields.fine_amount && formErrors.fine_amount && (
                    <ErrorMessage>{formErrors.fine_amount}</ErrorMessage>
                  )}
                </Field>

                <Field>
                  <Label htmlFor="due_date">Due Date</Label>
                  <Input
                    id="due_date"
                    name="due_date"
                    type="date"
                    value={newViolation.due_date?.split('T')[0] || ''}
                    onChange={handleInputChange}
                  />
                </Field>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Field>
                  <Label htmlFor="description">Description*</Label>
                  <Textarea
                    id="description"
                    name="description"
                    value={newViolation.description}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="300 character limit"
                    required
                    data-invalid={
                      touchedFields.description && formErrors.description
                    }
                    rows={2}
                    maxLength={300}
                  />

                  {touchedFields.description && formErrors.description && (
                    <ErrorMessage>{formErrors.description}</ErrorMessage>
                  )}
                </Field>

                <Field>
                  <Label htmlFor="date_of_violation">Date of Violation*</Label>
                  <Input
                    id="date_of_violation"
                    name="date_of_violation"
                    type="date"
                    value={newViolation.date_of_violation}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    required
                    data-invalid={
                      touchedFields.date_of_violation &&
                      formErrors.date_of_violation
                    }
                  />
                  {touchedFields.date_of_violation &&
                    formErrors.date_of_violation && (
                      <ErrorMessage>
                        {formErrors.date_of_violation}
                      </ErrorMessage>
                    )}
                </Field>
              </div>

              <Field>
                <Label htmlFor="message">Detailed Message*</Label>
                <Textarea
                  id="message"
                  name="message"
                  value={newViolation.message}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder="Detailed description of the violation"
                  required
                  data-invalid={touchedFields.message && formErrors.message}
                  rows={4}
                />
                {touchedFields.message && formErrors.message && (
                  <ErrorMessage>{formErrors.message}</ErrorMessage>
                )}
              </Field>

              {/* Image Upload */}
              {/* <Field>
                                <Label>Image Upload</Label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-zinc-900/25 px-6 py-10 dark:border-white/25">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-zinc-300 dark:text-zinc-500" />
                                        <div className="mt-4 flex text-sm/6 text-zinc-600 dark:text-zinc-400">
                                            <label
                                                htmlFor="image-upload"
                                                className="relative cursor-pointer rounded-md font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                                            >
                                                <span>Upload an image</span>
                                                <input
                                                    id="image-upload"
                                                    name="image-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    accept="image/*"
                                                    onChange={() =>
                                                        handleFileUpload(
                                                            'image'
                                                        )
                                                    }
                                                />
                                            </label>
                                            <p className="pl-1">
                                                or drag and drop
                                            </p>
                                        </div>
                                        <p className="text-xs/5 text-zinc-600 dark:text-zinc-400">
                                            PNG, JPG, GIF up to 10MB
                                        </p>
                                    </div>
                                </div>
                            </Field>

                            
                            <Field>
                                <Label>Document Upload</Label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-zinc-900/25 px-6 py-10 dark:border-white/25">
                                    <div className="text-center">
                                        <DocumentIcon className="mx-auto h-12 w-12 text-zinc-300 dark:text-zinc-500" />
                                        <div className="mt-4 flex text-sm/6 text-zinc-600 dark:text-zinc-400">
                                            <label
                                                htmlFor="document-upload"
                                                className="relative cursor-pointer rounded-md font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                                            >
                                                <span>Upload a document</span>
                                                <input
                                                    id="document-upload"
                                                    name="document-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    accept=".pdf,.doc,.docx"
                                                    onChange={() =>
                                                        handleFileUpload(
                                                            'document'
                                                        )
                                                    }
                                                />
                                            </label>
                                            <p className="pl-1">
                                                or drag and drop
                                            </p>
                                        </div>
                                        <p className="text-xs/5 text-zinc-600 dark:text-zinc-400">
                                            PDF, DOC, DOCX up to 10MB
                                        </p>
                                    </div>
                                </div>
                            </Field> */}
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button onClick={handleDialogClose} color="zinc">
            Cancel
          </Button>
          <Button
            onClick={handleSaveViolation}
            color="blue"
            disabled={!isFormValid()}
            className="disabled:opacity-50"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViolationList;
