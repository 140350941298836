import React, { useState, useEffect } from 'react';
import { Heading } from './Components/Catalyst/heading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './Components/Catalyst/table';
import { HoaCreateOrUpdateSchema, HoaSchema } from './hoa-api-client';
import { hoaService } from './ApiClients';
import { Input, InputGroup } from './Components/Catalyst/input';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from './Components/Catalyst/button';
import {
  Dialog,
  DialogTitle,
  DialogBody,
  DialogActions,
} from './Components/Catalyst/dialog';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from './Components/Catalyst/fieldset';
import { Textarea } from './Components/Catalyst/textarea';
import { useNavigate } from 'react-router-dom';
import { Divider } from './Components/Catalyst/divider';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from './Components/Catalyst/dropdown';
import usStates from './utils/addressUtils';
import ShowAlert from './ShowAlert';
import LoadingSpinner from './LoadingSpinner';
import { formatPhoneNumber, stripPhoneFormatting } from './utils/phoneUtils';

interface FormErrors {
  name?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  contact_phone?: string;
  contact_email?: string;
  website_url?: string;
}

const initialHoaState: HoaCreateOrUpdateSchema = {
  name: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: 'USA',
  contact_email: '',
  contact_phone: '',
  website_url: '',
  description: '',
  is_active: true,
};

interface TouchedFields {
  [key: string]: boolean;
}

const HOAList: React.FC = () => {
  const navigate = useNavigate();
  const [hoas, setHoas] = useState<HoaSchema[]>([]);
  const [filteredHoas, setFilteredHoas] = useState<HoaSchema[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [errorTitle, setErrorTitle] = useState<string | null>(null);
  const [showCreateHoaError, setShowCreateHoaError] = useState(false);
  const [fetchErrorTitle, setFetchErrorTitle] = useState<string>('');
  const [fetchError, setFetchError] = useState<string>('');
  const [showFetchError, setShowFetchError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newHoa, setNewHoa] =
    useState<HoaCreateOrUpdateSchema>(initialHoaState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});

  useEffect(() => {
    const fetchHoas = async () => {
      try {
        setLoading(true);
        const response = await hoaService.getAllHoas();
        setHoas(response.data);
        setFilteredHoas(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching HOAs:', err);
        let errorMessage = 'An unexpected error occurred while fetching HOAs.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setFetchErrorTitle('Failed to Load HOAs');
        setFetchError(errorMessage);
        setShowFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchHoas();
  }, []);

  useEffect(() => {
    const filtered = hoas.filter(
      (hoa) =>
        hoa.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getFullAddress(hoa).toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredHoas(filtered);
  }, [searchTerm, hoas]);

  const getFullAddress = (hoa: HoaSchema): string => {
    const parts = [
      hoa.street,
      hoa.street2,
      hoa.city,
      hoa.state,
      hoa.zip,
      hoa.country,
    ].filter(Boolean);
    return parts.join(', ');
  };

  const handleRowClick = (hoa: HoaSchema) => {
    navigate(`/hoa/${hoa.id}`);
  };

  const handleAddHoa = () => {
    setIsDialogOpen(true);
  };

  const resetForm = () => {
    setNewHoa(initialHoaState);
    setFormErrors({});
    setTouchedFields({});
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    resetForm();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const validateField = (
    name: keyof HoaCreateOrUpdateSchema,
    value: string | boolean | null | undefined
  ): string | undefined => {
    switch (name) {
      case 'name':
        return !value ? 'Name is required' : undefined;
      case 'street':
        return !value ? 'Street address is required' : undefined;
      case 'city':
        return !value ? 'City is required' : undefined;
      case 'state':
        return !value ? 'State is required' : undefined;
      case 'zip':
        return !value
          ? 'ZIP code is required'
          : !/^\d{5}(-\d{4})?$/.test(value.toString())
            ? 'Invalid ZIP code format'
            : undefined;
      case 'contact_phone':
        const cleanedPhone = stripPhoneFormatting(value?.toString() || '');
        return !cleanedPhone
          ? 'Contact phone is required'
          : !/^\d{10,11}$/.test(cleanedPhone)
            ? 'Phone number must be 10 or 11 digits'
            : undefined;
      case 'contact_email':
        return !value
          ? 'Contact email is required'
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.toString())
            ? 'Invalid email format'
            : undefined;
      case 'website_url':
        if (!value) return undefined;
        try {
          new URL(value.toString());
          return undefined;
        } catch {
          return 'Invalid website URL format';
        }
      default:
        return undefined;
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HoaCreateOrUpdateSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name as keyof FormErrors] = error;
      } else {
        delete newErrors[name as keyof FormErrors];
      }
      return newErrors;
    });
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    (Object.keys(newHoa) as Array<keyof HoaCreateOrUpdateSchema>).forEach(
      (key) => {
        validateField(key, newHoa[key]);
        if (formErrors[key as keyof FormErrors]) {
          errors[key as keyof FormErrors] = formErrors[key as keyof FormErrors];
        }
      }
    );
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'contact_phone') {
      const cleanedPhone = stripPhoneFormatting(value);
      setNewHoa((prev) => ({ ...prev, [name]: cleanedPhone }));
      setTouchedFields((prev) => ({ ...prev, [name]: true }));

      const error = validateField(
        name as keyof HoaCreateOrUpdateSchema,
        cleanedPhone
      );
      setFormErrors((prev) => {
        const newErrors = { ...prev };
        if (error) {
          newErrors[name as keyof FormErrors] = error;
        } else {
          delete newErrors[name as keyof FormErrors];
        }
        return newErrors;
      });
      return;
    }

    setNewHoa((prev) => ({ ...prev, [name]: value }));
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof HoaCreateOrUpdateSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name as keyof FormErrors] = error;
      } else {
        delete newErrors[name as keyof FormErrors];
      }
      return newErrors;
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNewHoa((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSaveHoa = async () => {
    if (validateForm()) {
      try {
        const response = await hoaService.createHoa({
          hoaCreateOrUpdateSchema: newHoa,
        });
        setHoas((prevHoas) => [...prevHoas, response.data]);
        handleDialogClose();
      } catch (err) {
        console.error('Error creating HOA:', err);
        let errorMessage = 'An unexpected error occurred. Please try again.';
        if (err instanceof Error) {
          errorMessage = err.message;
        } else if (typeof err === 'string') {
          errorMessage = err;
        }

        setErrorTitle('Failed to create HOA. Please review your data.');
        setError(errorMessage);
        setShowCreateHoaError(true);
      }
    }
  };

  const isFormValid = () => {
    // Check for any existing errors
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    // Required fields
    const requiredFields = [
      'name',
      'street',
      'city',
      'state',
      'zip',
      'contact_phone',
      'contact_email',
    ];

    return requiredFields.every((field) => {
      const value = newHoa[field as keyof HoaCreateOrUpdateSchema];
      return value !== undefined && value !== '';
    });
  };

  if (loading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <Heading level={4}>HOA List</Heading>
        <div className="flex items-center space-x-4">
          <Button onClick={handleAddHoa}>
            <PlusIcon className="h-5 w-5 mr-2" />
            Add HOA
          </Button>
          <div className="w-64">
            <InputGroup>
              <MagnifyingGlassIcon data-slot="icon" />
              <Input
                type="search"
                placeholder="Search by name or address..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <ShowAlert
        alertType="error"
        alertTitle={fetchErrorTitle}
        alertMessage={fetchError}
        isVisible={showFetchError}
        onClose={() => setShowFetchError(false)}
        timeout={5000}
        isInline={true}
      />
      <div className="bg-white dark:bg-zinc-800 rounded-lg shadow transition-colors duration-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <div className="max-h-[500px] overflow-y-auto">
                  <Table>
                    <TableHead className="sticky top-0 z-10 bg-white dark:bg-zinc-800">
                      <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Address</TableHeader>
                        <TableHeader>Contact Email</TableHeader>
                        <TableHeader>Contact Phone</TableHeader>
                        <TableHeader>Status</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredHoas.map((hoa) => (
                        <TableRow
                          key={hoa.id}
                          onClick={() => handleRowClick(hoa)}
                          className="cursor-pointer hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-150"
                        >
                          <TableCell>{hoa.name}</TableCell>
                          <TableCell>{getFullAddress(hoa)}</TableCell>
                          <TableCell>{hoa.contact_email}</TableCell>
                          <TableCell>
                            {hoa.contact_phone
                              ? formatPhoneNumber(hoa.contact_phone)
                              : ''}
                          </TableCell>
                          <TableCell>
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                hoa.is_active
                                  ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                                  : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                              }`}
                            >
                              {hoa.is_active ? 'Active' : 'Inactive'}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={isDialogOpen} onClose={handleDialogClose} size="2xl">
        <div className="relative">
          <DialogTitle>New HOA</DialogTitle>
          <button
            onClick={handleDialogClose}
            className="absolute right-0 top-0 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
            aria-label="Close dialog"
          >
            <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
          </button>
        </div>
        <Divider className="mt-4 mb-6" />

        <div className="mb-4">
          <ShowAlert
            alertType="error"
            alertTitle={errorTitle ?? 'Something went wrong'}
            alertMessage={error ?? 'Please try again'}
            isVisible={showCreateHoaError}
            onClose={() => setShowCreateHoaError(false)}
            timeout={5000}
            isInline={true}
          />
        </div>

        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <div className="grid grid-cols-5 gap-4">
                <Field className="col-span-4">
                  <Label htmlFor="name">HOA Name*</Label>
                  <Input
                    id="name"
                    name="name"
                    value={newHoa.name}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Enter HOA name"
                    required
                    data-invalid={touchedFields.name && formErrors.name}
                  />
                  {touchedFields.name && formErrors.name && (
                    <ErrorMessage>{formErrors.name}</ErrorMessage>
                  )}
                </Field>
                <Field className="col-span-1">
                  <Label className="invisible">Spacer</Label>
                  <div className="flex items-center h-10 mt-2">
                    <input
                      id="is_active"
                      type="checkbox"
                      name="is_active"
                      checked={newHoa.is_active}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                    />
                    <Label htmlFor="is_active">Is Active</Label>
                  </div>
                </Field>
              </div>
              <Field>
                <Label htmlFor="street">Street*</Label>
                <Input
                  id="street"
                  name="street"
                  value={newHoa.street}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder="Enter street address"
                  required
                  data-invalid={touchedFields.street && formErrors.street}
                />
                {touchedFields.street && formErrors.street && (
                  <ErrorMessage>{formErrors.street}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label htmlFor="street2">Street 2</Label>
                <Input
                  id="street2"
                  name="street2"
                  value={newHoa.street2 || ''}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder="Enter additional street information (optional)"
                />
              </Field>

              <div className="grid grid-cols-5 gap-4">
                <Field className=" col-span-2">
                  <div className="mb-2">
                    <Label htmlFor="city">City*</Label>
                  </div>
                  <Input
                    id="city"
                    name="city"
                    value={newHoa.city}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Enter city"
                    required
                    data-invalid={touchedFields.city && formErrors.city}
                  />
                  {touchedFields.city && formErrors.city && (
                    <ErrorMessage>{formErrors.city}</ErrorMessage>
                  )}
                </Field>
                <Field className=" col-span-1">
                  <div className="mb-2">
                    <Label htmlFor="state">State*</Label>
                  </div>
                  <Description></Description>
                  <Dropdown>
                    <DropdownButton
                      outline
                      className="w-full px-3 py-2 flex justify-between items-center text-left"
                    >
                      {newHoa?.state || 'State'}
                      <ChevronDownIcon className="h-5 w-5 ml-2" />
                    </DropdownButton>
                    <DropdownMenu className="max-h-60 overflow-y-auto">
                      {usStates.map((state) => (
                        <DropdownItem
                          key={state.label}
                          onClick={() =>
                            handleInputChange({
                              target: {
                                name: 'state',
                                value: state.value,
                              },
                            } as any)
                          }
                        >
                          {state.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>

                  {touchedFields.state && formErrors.state && (
                    <ErrorMessage>{formErrors.state}</ErrorMessage>
                  )}
                </Field>
                <Field className=" col-span-2">
                  <div className="mb-2">
                    <Label htmlFor="zip">ZIP Code*</Label>
                  </div>
                  <Input
                    id="zip"
                    name="zip"
                    value={newHoa.zip}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Enter ZIP code"
                    required
                    data-invalid={touchedFields.zip && formErrors.zip}
                  />
                  {touchedFields.zip && formErrors.zip && (
                    <ErrorMessage>{formErrors.zip}</ErrorMessage>
                  )}
                </Field>
              </div>

              <div className="grid grid-cols-5 gap-4">
                <Field className="col-span-3">
                  <Label htmlFor="contact_email">Contact Email*</Label>
                  <Input
                    id="contact_email"
                    name="contact_email"
                    type="email"
                    value={newHoa.contact_email || ''}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Enter contact email"
                    data-invalid={
                      touchedFields.contact_email && formErrors.contact_email
                    }
                  />
                  {touchedFields.contact_email && formErrors.contact_email && (
                    <ErrorMessage>{formErrors.contact_email}</ErrorMessage>
                  )}
                </Field>
                <Field className="col-span-2">
                  <Label htmlFor="contact_phone">Contact Phone*</Label>
                  <Input
                    id="contact_phone"
                    name="contact_phone"
                    type="tel"
                    value={
                      newHoa.contact_phone
                        ? formatPhoneNumber(newHoa.contact_phone)
                        : ''
                    }
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Enter contact phone"
                    data-invalid={
                      touchedFields.contact_phone && formErrors.contact_phone
                    }
                  />
                  {touchedFields.contact_phone && formErrors.contact_phone && (
                    <ErrorMessage>{formErrors.contact_phone}</ErrorMessage>
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-5 gap-4">
                <Field className="col-span-3">
                  <Label htmlFor="website_url">Website URL</Label>
                  <Input
                    id="website_url"
                    name="website_url"
                    type="url"
                    value={newHoa.website_url || ''}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Enter website URL"
                    data-invalid={
                      touchedFields.website_url && formErrors.website_url
                    }
                  />
                  {touchedFields.website_url && formErrors.website_url && (
                    <ErrorMessage>{formErrors.website_url}</ErrorMessage>
                  )}
                </Field>
              </div>
              <Field>
                <Label htmlFor="description">Description</Label>

                <Textarea
                  id="description"
                  name="description"
                  value={newHoa.description || ''}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  placeholder="500 character limit"
                  rows={3}
                  maxLength={500}
                />
              </Field>
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button onClick={handleDialogClose} color="zinc">
            Cancel
          </Button>
          <Button
            onClick={handleSaveHoa}
            color="blue"
            disabled={!isFormValid()}
            className="disabled:opacity-50"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HOAList;
