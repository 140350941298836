import React from 'react';

interface SpinnerProps {
  size?: 'sm' | 'md' | 'lg';
  color?: 'cyan' | 'blue' | 'zinc' | 'red' | 'green';
}

const LoadingSpinner = ({ size = 'lg', color = 'cyan' }: SpinnerProps) => {
  const sizeClasses = {
    sm: 'w-4 h-4',
    md: 'w-8 h-8',
    lg: 'w-12 h-12',
  };

  const colorClasses = {
    cyan: 'text-cyan-300',
    blue: 'text-blue-300',
    zinc: 'text-zinc-300',
    red: 'text-red-300',
    green: 'text-green-300',
  };

  return (
    <div className="flex items-center justify-center">
      <div
        className={`${sizeClasses[size]} ${colorClasses[color]} mt-2 mb-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        role="status"
      ></div>
    </div>
  );
};

export default LoadingSpinner;
